* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PlusJakartaSans-Light;
}

body {
  font-family: PlusJakartaSans-Light;
}

// UNIVERSAL FONT FAMILY
@font-face {
  font-family: PlusJakartaSans-Bold;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf);
}

@font-face {
  font-family: PlusJakartaSans-ExtraBold;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.ttf);
}

@font-face {
  font-family: PlusJakartaSans-ExtraLight;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-ExtraLight.ttf);
}

@font-face {
  font-family: PlusJakartaSans-Light;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Light.ttf);
}

@font-face {
  font-family: PlusJakartaSans-Medium;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf);
}

@font-face {
  font-family: PlusJakartaSans-Regular;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf);
}

@font-face {
  font-family: PlusJakartaSans-SemiBold;
  src: url(./Assests/All-Fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf);
}

.social-links {
  display: none;
}



// UNIVERSAL CSS
$primary-color: #FE6601;

p {
  font-family: PlusJakartaSans-Medium;
}

.short-head {
  font-size: 12px;
  letter-spacing: 0.14rem;
  color: #FE6601;
  text-transform: uppercase;
}

.main-head {
  font-size: 42px;
  text-transform: capitalize;
  color: #000;
}

.text-desc {
  font-size: 20px;
  font-family: PlusJakartaSans-Medium;
  font-size: 15px;
  text-align: justify;
}

.common-btn {
  border: none;
  outline: none;
  background-color: #FE6601;
  color: #fff;
  font-size: 11px;
  padding: 13px 50px;
  border-radius: 5px;
  letter-spacing: 0.13rem;
  font-family: PlusJakartaSans-SemiBold;
  margin-top: 30px;
  width: 235px;
  a{
    color: #fff;
  }
}

h1,
h2,
h3,
h4 {
  font-family: PlusJakartaSans-SemiBold;
}

img {
  width: 100%;
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

p,
ul {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
}

li {
  list-style: none;
}

.btn {
  padding: 0 !important;
}

input::placeholder {
  text-transform: capitalize;
  font-size: 14px;
}