#navbar {

    .navbar {
        padding: 20px 0 0 0;

        .navbar-brand {

            h1,
            span {
                text-transform: uppercase;
                font-size: 35px;
                letter-spacing: 0.01rem;
                font-family: PlusJakartaSans-ExtraBold;

            }

            h6 {
                font-size: 11px;
                font-family: 'PlusJakartaSans-SemiBold';
            }

            span {
                color: #FE6601;
            }
        }

        .navbar-collapse {
            align-items: center;
            justify-content: flex-end;

            .navbar-nav {
                li {
                    a {
                        color: #000 !important;
                        text-transform: capitalize;
                        font-family: PlusJakartaSans-Medium;
                        padding-right: 30px;
                        font-size: 15px;

                        &:hover {
                            color: #FE6601 !important;
                        }
                    }
                }

            }

        }



        .social-links {
            .first-child {
                svg {
                    font-size: 55px;
                }
            }

            a {
                svg {
                    font-size: 60px;
                    color: #FE6601;
                    padding-left: 42px;
                }
            }
        }


    }
}




@media only screen and (max-width: 600px) {
    .main-head {
        font-size: 35px;

    }

    #navbar {

        .navbar {
            .navbar-collapse {
                background-color: #fff;
                padding: 10px 20px;
                border-radius: 7px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            }

            .social-links {
                display: none;
            }
        }
    }



}

@media only screen and (min-width: 600px) and (max-width:992px) {
    #navbar {
        .navbar {
            .social-links {
                display: none;
            }

            .navbar-brand {

                h1,
                span {
                    font-size: 25px;
                    margin-bottom: 0;
                }
            }

            .navbar-collapse {
                .navbar-nav {
                    li {
                        a {
                            padding-left: 30px;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}