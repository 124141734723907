// banner
#banner {
    .left-side {
        padding-right: 50px;
        padding-top: 40px;

        p {
            text-align: justify;

        }
    }

    .right-side {
        img {
            height: 450px;
            object-fit: cover;
            border-radius: 10px;
        }
    }
}

// portfolios
#portfolios {
    background-image: url(../../Assests/Img/footer-map.webp);
    background-color: rgb(0 0 0 / 90%);
    padding: 60px 0;
    margin-top: 60px;
    text-align: center;
    color: #fff;

    .main-head {
        color: #FE6601;
        text-transform: inherit;
    }

    h2 {
        font-size: 55px;
        padding: 10px 0;
    }

    p {
        font-size: 17px;
    }


    .Figures {
        font-size: 15px;
        padding-top: 4px;
    }
}

// barriers
#barriers {
    padding: 50px 0;

    .right-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .main-head {
            font-size: 36px;
        }
    }

    .barriers1 {
        // object-position: -269px 0;
    }

    img {
        height: 450px;
        object-fit: cover;
        border-radius: 5px;
    }
}

// invest
#invest {
    background-color: #FE6601;
    text-align: center;
    padding: 60px 0;

    .text-desc {
        text-align: center;
        padding: 0 100px;
        color: #000;
        padding-top: 10px;
        font-size: 14px;
    }

    img {
        height: 296px;
        object-fit: cover;
        margin-top: 20px;
        object-position: 0px 12px;
    }
}

// alone
#alone {
    padding: 50px 0;
    text-align: center;

    .text-desc {
        text-align: center;
    }

    .col-lg-3 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 40px;

        .circle {
            border: 1px solid #FE6601;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
                font-size: 35px;
            }

            p {
                font-size: 14px;
                padding: 0 20px;
            }
        }
    }
}


// count number
#events {
    background-color: #22252a;
    padding: 40px 0;
    color: #ffffffc2;

    .main-head {
        text-align: center;
        color: #FE6601;
    }

    .events-box1 {
        padding-right: 40px;
    }

    .events-box2 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .events-box3 {
        padding-left: 40px;
    }

    .events-spacing {
        padding-top: 30px;

        .events-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            img {
                height: 290px;
                object-fit: cover;
                border-radius: 5px;
            }

            h2 {
                font-size: 22px;
                color: #fff;
                padding: 15px 0 2px 0;
            }

            p {
                font-size: 14px;
                font-family: PlusJakartaSans-Light;
            }

            .event-btn {
                display: flex;
                gap: 15px;
                padding-top: 25px;

                .btn1 {
                    border: 1px solid #fff;
                    width: 45px;

                    svg {
                        font-size: 20px;
                    }

                }

                .btn2 {
                    border: 1px solid #FE6601;
                    background-color: #FE6601;
                    padding: 0 20px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    cursor: default;

                    svg {
                        padding-right: 10px;
                        font-size: 30px;
                    }

                    p {
                        font-size: 13px;
                        border-left: 1px solid #fff;
                        padding-left: 10px;
                    }
                }

                .btn {
                    height: 45px;

                    svg {
                        color: #fff;

                    }
                }
            }
        }
    }
}


// meet-the-host
#meet-the-host {
    text-align: center;
    padding: 60px 0;

    h1.main-head {
        padding-bottom: 60px;
    }

    .left-side-main {
        padding-right: 60px;

        .left-side {
            background-color: #FE6601;
            color: #fff;
            padding: 40px 30px 30px 30px;
            border-radius: 6px;

            img {
                width: 180px;
                height: 180px;
                border-radius: 50%;
                object-fit: cover;
                margin-top: -110px;
            }

            h2 {
                font-size: 20px;
                font-family: PlusJakartaSans-Bold;
                padding-top: 20px;
            }

            p {
                font-size: 14px;
                padding-top: 23px;
                padding-bottom: 30px;
            }

            a {
                svg {
                    color: #fff;
                    font-size: 22px;
                }
            }
        }
    }

    .right-side {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
            font-size: 25px;
            font-family: PlusJakartaSans-ExtraBold;
            // text-transform: capitalize;
        }

        .location {
            padding-top: 25px;
            margin-bottom: 2px;
        }

        .common-btn {
            margin-top: 25px;
        }
    }
}

// join
#join {
    background-color: #FE6601;
    text-align: center;
    padding: 60px 0;

    .main-head {
        // color: #fff;
    }

    .text-desc {
        text-align: center;
        // color: #fff;
    }

    .for-top-spacing {
        padding-top: 40px;

        .icon-box {
            position: relative;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 5px;
            height: 334px;

            img {
                font-size: 40px;
                color: #fff;
                background-color: #000;
                width: 65px;
                height: 65px;
                border-radius: 50%;
                padding: 10px;
            }

            h3 {
                font-size: 20px;
                color: #FE6601;
                padding-top: 20px;
            }

            p {
                font-size: 14px;
            }

            hr {
                border-top-width: 2px;
                width: 50%;
                margin: 50px auto 0;
                position: absolute;
                bottom: 20px;
                left: 25%;
            }
        }
    }
}

// testimonial
#testimonial {
    padding: 60px 0;
    text-align: center;

    .carousel {
        position: relative;
        padding-top: 30px;
        padding-left: 23px;
        padding-right: 12px;


        // span.custom-prev {
        //     position: absolute;
        //     left: -41px;
        //     top: 40%;
        // }
        // span.custom-next
        // {
        //     position: absolute;
        //     right: -41px;
        //     top:40%
        // }
        span.custom-prev,
        span.custom-next {
            img {
                width: 30px;
            }
        }

        .owl-nav {
            display: block;

            .owl-prev {
                span {
                    // padding-right: 10px;
                }
            }

            .owl-next {
                span {
                    // padding-left: 10px;
                }
            }

            span {
                font-size: 37px;
                line-height: 0.7;
            }
        }

        .owl-carousel .owl-nav button.owl-next:hover,
        .owl-carousel .owl-nav button.owl-prev:hover {
            background-color: #FE6601;
        }

        .owl-carousel .owl-nav button.owl-next,
        .owl-carousel .owl-nav button.owl-prev {
            width: 40px;
            height: 40px;
            background-color: #ccc;
        }

        .owl-dots {
            display: none;
            align-items: center;
            justify-content: center;
            padding-top: 30px;
        }

        .owl-theme .owl-dots .owl-dot.active span {
            background-color: #FE6601;
            width: 20px;
            height: 20px;
        }

        .owl-theme .owl-dots .owl-dot span {
            width: 15px;
            height: 15px;
        }

        .owl-item {

            .item {
                .item-inner {
                    display: flex;
                    width: 100%;
                    background-color: #FDF9ED;
                    padding: 30px;
                    height: 370px;

                    .img-side {
                        width: 50%;
                        padding-right: 30px;

                        img {
                            height: 230px;
                            object-fit: cover;
                        }

                        h3 {
                            font-size: 22px;
                            font-family: PlusJakartaSans-Bold;
                            padding: 15px 0 0 0;
                            text-transform: capitalize;
                        }

                        p {
                            font-size: 13px;
                            text-transform: capitalize;
                            padding-top: 7px;

                            a {
                                color: blue;
                                padding-left: 5px;


                                svg {
                                    font-size: 14px;
                                    margin-top: -2px;
                                }
                            }
                        }
                    }

                    .text-side {
                        width: 50%;

                        p {
                            font-size: 13px;
                            text-align: justify;

                        }
                    }
                }
            }
        }
    }
}

// mission
#mission {
    background-color: #22252a;
    padding: 60px 0;
    color: #ffffffc2;

    .left-side {
        padding-right: 75px;
    }

    .right-side {
        padding-left: 75px;
    }

    img {
        height: 180px;
        object-fit: cover;
        border-radius: 4px;
        filter: grayscale(1);
    }

    h3 {
        font-size: 32px;
        font-family: PlusJakartaSans-Bold;
        color: #FE6601;
        padding: 20px 0 5px 0;
    }

    p {
        font-size: 14px;
        font-family: 'PlusJakartaSans-Light';
        color: #fff;
    }

}

// faq
#faq {
    padding: 60px 0 30px 0;

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url(../../Assests//Img/down1-arrow.svg);
    }

    button.accordion-button.collapsed::after {
        background-image: url(../../Assests//Img/down-arrow.svg);
    }

    div#accordionExample {
        padding-top: 25px;

        .accordion-button {
            background-color: #FDF9ED;
            border: none;
            margin-bottom: 0px;
            border-radius: 0;
            box-shadow: none;
            padding: 20px;
        }

        .accordion-item {
            background-color: #FDF9ED;
            border: none;
            margin-bottom: 25px;
            box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

            .accordion-button:not(.collapsed) {
                background-color: #FDF9ED;
                color: #FE6601;
            }
        }

        .accordion-body {
            padding-top: 0;
            padding-bottom: 40px;

            p {
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    div#banner {
        padding-top: 20px;

        .right-side {
            padding-top: 30px;

            img {
                height: auto;
            }
        }

        .left-side {
            padding-right: 12px;
            padding-top: 10px;
        }
    }

    // portfolios
    #portfolios {
        padding-left: 10px;
        padding-right: 10px;

        p {
            font-size: 15px;
        }

        #portfolios h2 {
            font-size: 45px;
        }
    }

    // barriers
    #barriers {
        img {
            // height: 282px;
        }

        .barriers1 {
            object-position: 0px 0;
            padding-bottom: 20px;
        }

        .right-side {
            padding-top: 20px;
        }
    }

    // invest
    #invest {
        .text-desc {
            padding: 0 10px;
        }

        img {
            height: auto;
        }
    }

    // events
    #events {
        .events-box1 {
            padding-right: 12px;
        }

        .events-box2 {
            padding-right: 12px;
            padding-left: 12px;
        }

        .events-box3 {
            padding-left: 12px;
        }

        .events-spacing {
            .events-box {
                padding-bottom: 30px;
            }
        }
    }

    // meet-the-host
    #meet-the-host {
        padding: 60px 12px;

        h1.main-head {
            padding-bottom: 100px;
        }

        .left-side-main {
            padding-right: 10px;
            padding-left: 12px;

            #meet-the-host .left-side-main .left-side img {
                margin-top: 0px;
            }
        }

        .right-side {
            padding-top: 20px;
        }
    }

    // join
    #join {
        .for-top-spacing {
            .icon-box {
                margin-bottom: 25px;
            }
        }
    }


    // mission
    #mission {
        .left-side {
            padding-right: 12px;
        }

        .right-side {
            padding-left: 12px;
            padding-top: 20px;
        }
    }

    // faq
    #faq {
        padding: 60px 12px 30px 12px;
    }

    // #faq
    div#faq {
        .main-head {
            text-align: center;
        }
    }


    // testimonial
    #testimonial {
        .carousel {
            .owl-item {
                .item {
                    .item-inner {
                        display: block;
                        height: auto;

                        .img-side {
                            padding-right: 0px;
                            width: 100%;
                        }

                        .text-side {
                            width: 100%;

                            p {
                                padding-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width:992px) {
    #banner {
        .right-side {
            img {
                padding-top: 20px;
            }
        }
    }

    // portfolios
    #portfolios {
        padding-left: inherit;
        padding-right: inherit;
    }

    // barriers
    #barriers {
        .right-side {
            padding-top: 20px;
        }
    }

    // events
    #events {
        .events-box1 {
            padding-right: 14px;
        }

        .events-spacing {
            .events-box {
                padding-bottom: 30px;
            }

            .events-box3 {
                padding-left: 15px;
            }
        }
    }

    #mission {
        .left-side {
            padding-right: 25px;
        }

        .right-side {
            padding-left: 25px;
        }
    }

    // join
    #join {
        .for-top-spacing {
            .icon-box {
                margin-bottom: 30px;
            }
        }
    }


    // meet-the-host
    #meet-the-host {

        .left-side-main {
            padding-right: 10px;

            .left-side {
                padding: 20px 30px 30px 30px;
                p {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
                img {
                    margin-top: 0px;
                }
            }

        }

        .right-side {
            .location {
                padding-top: 15px;
            }

            .common-btn {
                margin-top: 15px;
            }
        }
    }

    #testimonial {
        .carousel {
            .owl-item {
                .item {
                    .item-inner {
                        height: auto;
                    }
                }
            }
        }
    }
}