footer {
    background-color: #22252a;
    padding: 60px 0;
    color: #ffffffc2;

    .desktop-none {
        display: none;
    }

    .mobile-none {
        display: block;
    }

    #footer {
        h3 {
            color: #FE6601;
            text-transform: capitalize;
            font-family: PlusJakartaSans-Medium;
            font-size: 23px;
        }

        ul {
            padding-left: 0rem;
            padding-top: 20px;

            a {
                color: #fff;
            }

            li {
                text-transform: capitalize;
                padding-bottom: 20px;
                font-size: 15px;
            }

            .display-flex {
                a {
                    display: flex;
                    color: #ffffffc2;
                }


                span {
                    color: #FE6601;
                    font-size: 22px;
                    margin-top: -7px;
                    padding-right: 10px;
                }
            }

        }

        .brand {
            text-align: center;
            padding: 0 50px;

            .brand-logo {
                h1 {
                    text-transform: uppercase;
                    font-size: 35px;
                    letter-spacing: 0.01rem;
                    font-family: PlusJakartaSans-ExtraBold;
                    color: #fff;
                }

                span {
                    color: #FE6601;
                    font-family: PlusJakartaSans-ExtraBold;
                }

            }

            p {
                font-family: PlusJakartaSans-Light;
                font-size: 15px;
            }

            .social-links {
                padding-top: 25px;

                a {
                    padding-right: 40px;

                    svg {
                        color: #fff;
                        font-size: 22px;
                    }
                }
            }
        }

        .textcenter {
            text-align: center;
            padding-top: 30px;
            padding-bottom: 30px;
            display: none;

            p {
                font-size: 14px;
                font-family: PlusJakartaSans-Light;
            }
        }
    }
}

.get-in-touch {
    ul {
        li {
            text-transform: inherit !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    footer {
        .desktop-none {
            display: block;
        }

        .mobile-none {
            display: none;
        }

        .list-style {
            padding-top: 20px;
        }

        #footer {
            .brand {
                text-align: left;
                padding: 10px 12px 30px 12px;
            }


        }
    }
}


@media only screen and (min-width: 600px) and (max-width:992px) {
    footer {
        #footer {
            ul {
                li {
                    font-size: 14px;
                }
            }

            .brand {
                padding: 0 0px;
            }
        }
    }
}